import React, { Suspense, useState, useEffect } from 'react'
import { Route, Switch, useLocation, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import URLWarning from '../components/Header/URLWarning'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Home from './Home'
import { LocalLoader } from 'components/Loader'
import { ExternalLink, TYPE } from 'theme'
import { useActiveNetworkVersion, useSubgraphStatus } from 'state/application/hooks'
import { DarkGreyCard } from 'components/Card'
import {
  SUPPORTED_NETWORK_VERSIONS,
  BNBNetworkInfo,
  ArbitrumNetworkInfo,
  SupportedNetwork,
  KlaytnNetworkInfo,
} from 'constants/networks'
import { RedirectInvalidAsset } from './Asset/redirects'
import PoweredBy from 'components/PoweredBy'
import VeWomPage from './VeWom/VeWomPage'
import AssetsOverview from './Asset/AssetOverview'
import { BottomNavigationBar } from 'components/BottomNavigationBar'
import useBreakpoints from 'hooks/useBreakpoints'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
  min-height: 100vh;
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  position: fixed;
  justify-content: space-between;
  z-index: 2;
`

const BodyWrapper = styled.div<{ warningActive?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 24px;
  margin-top: ${({ warningActive }) => (warningActive ? '140px' : '75px')};
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  > * {
    max-width: 1300px;
  }

  @media (max-width: 1080px) {
    padding-top: 24px;
    margin-top: 75px;
  }
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: ${({ theme }) => theme.bg5};
  padding: 1rem;
  color: white;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

const BLOCK_DIFFERENCE_THRESHOLD = 30

export default function App() {
  // pretend load buffer
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 1300)
  }, [])

  const { isMobileLg } = useBreakpoints()
  // update network based on route
  // TEMP - find better way to do this
  const location = useLocation()
  const [activeNetwork, setActiveNetwork] = useActiveNetworkVersion()
  useEffect(() => {
    if (location.pathname === '/') {
      setActiveNetwork(KlaytnNetworkInfo)
    } else {
      SUPPORTED_NETWORK_VERSIONS.map((n) => {
        if (location.pathname.includes(n.route.toLocaleLowerCase())) {
          setActiveNetwork(n)
        }
      })
    }
  }, [location.pathname, setActiveNetwork])

  // subgraph health
  const [subgraphStatus] = useSubgraphStatus()
  const showNotSyncedWarning =
    subgraphStatus.headBlock && subgraphStatus.syncedBlock
      ? subgraphStatus.headBlock - subgraphStatus.syncedBlock > BLOCK_DIFFERENCE_THRESHOLD
      : false

  return (
    <Suspense fallback={null}>
      <Route component={GoogleAnalyticsReporter} />
      <Route component={DarkModeQueryParamReader} />
      {loading ? (
        <LocalLoader fill={true} />
      ) : (
        <AppWrapper>
          <URLWarning />
          <HeaderWrapper>
            {showNotSyncedWarning && (
              <WarningWrapper>
                <WarningBanner>
                  {`Warning: 
                  Data has only synced to block ${subgraphStatus.syncedBlock} (out of ${subgraphStatus.headBlock}). Please check back soon.`}
                </WarningBanner>
              </WarningWrapper>
            )}
            <Header />
          </HeaderWrapper>
          {subgraphStatus.available === false ? (
            <AppWrapper>
              <BodyWrapper>
                <DarkGreyCard style={{ maxWidth: '340px' }}>
                  <TYPE.label>
                    The Graph hosted network which provides data for this site is temporarily experiencing issues. Check
                    current status{' '}
                    <ExternalLink href="https://thegraph.com/hosted-service/subgraph/uniswap/uniswap-v3">
                      here.
                    </ExternalLink>
                  </TYPE.label>
                </DarkGreyCard>
              </BodyWrapper>
            </AppWrapper>
          ) : (
            <BodyWrapper warningActive={showNotSyncedWarning}>
              <Popups />
              <Switch>
                <Route exact strict path="/:networkID?/assets/:address" component={RedirectInvalidAsset} />
                <Route exact strict path="/:networkID?/assets" component={AssetsOverview} />
                <Route
                  exact
                  strict
                  path="/:networkID?/veWom"
                  component={activeNetwork.id !== SupportedNetwork.ETHEREUM ? VeWomPage : Home}
                />
                <Route exact path="/:networkID?/overview" component={Home} />
                <Redirect exact from="/:networkID?" to="/:networkID?/overview" />
              </Switch>
              <Marginer style={{ marginTop: '3rem' }} />
            </BodyWrapper>
          )}
          {activeNetwork === BNBNetworkInfo && <PoweredBy />}
          {isMobileLg && <BottomNavigationBar />}
        </AppWrapper>
      )}
    </Suspense>
  )
}
