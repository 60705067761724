import styled, { DefaultTheme } from 'styled-components'
import { createGlobalStyle } from 'styled-components'

export const ColorBackground = createGlobalStyle`
  body {
  }
`

export const PageWrapper = styled.div`
  width: 90%;
`

export const ThemedBackground = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${backgroundColor} 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-176vh);
`

export const ThemedBackgroundGlobal = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${backgroundColor} 0%, rgba(255, 255, 255, 0) 100%)`};
  transform: translateY(-150vh);
`

export const BackgroundGlobalTopLeft = styled.div<{ theme: DefaultTheme }>`
  position: absolute;
  left: -15.94%;
  right: 62.97%;
  top: -34.38%;
  bottom: 28.44%;

  background: ${({ theme }) => `${theme.wombatYellow1}`};
  opacity: 0.25;
  filter: blur(800px);
  z-index: -1;
`
export const BackgroundGlobalBottomRight = styled.div<{ theme: DefaultTheme }>`
  position: absolute;
  left: 53.83%;
  right: -11.09%;
  top: 21.72%;
  bottom: -36.25%;

  background: ${({ theme }) => `${theme.wombatPurple1}`};
  opacity: 0.25;
  filter: blur(500px);
  z-index: -1;
`
