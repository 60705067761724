import { ChartDayData } from '../../types/index'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import gql from 'graphql-tag'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { GLOBAL_TOKENS, GlobalTokensResponse, parseTokensDecimals } from './overview'
import { ClientType, useActiveNetworkVersion, useDataClient } from 'state/application/hooks'
import { SupportedNetwork } from 'constants/networks'
import * as BSCCacheData from './chart-cache-bsc.json'
import * as BSCLastDayCacheData from './chart-cache-last-day-bsc.json'
import * as ARBCacheData from './chart-cache-arb.json'
import * as ARBLastDayCacheData from './chart-cache-last-day-arb.json'
import * as ETHCacheData from './chart-cache-eth.json'
import * as ETHLastDayCacheData from './chart-cache-last-day-eth.json'
import { fillMissingData } from './chart-missing-data'

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)
const ONE_DAY_UNIX = 24 * 60 * 60

const GLOBAL_CHART = gql`
  query assetDayDatas($limit: Int!, $startDayId: Int!) {
    assetDayDatas(
      first: $limit
      where: { dayID_gt: $startDayId, asset_not: "0x0000000000000000000000000000000000000000" }
      orderBy: dayID
      orderDirection: asc
      subgraphError: allow
    ) {
      dayID
      dayVolume: dailyTradeVolumeUSD
      dailyLiability: dailyLiabilityUSD
      asset {
        id
        underlyingToken {
          id
        }
        poolAddress
      }
    }
  }
`

interface Data {
  dayID: string
  dailyLiability: string
  dayVolume: string
  asset: {
    id: string
    underlyingToken: {
      id: string
    }
    poolAddress: string | null
  }
}

interface ChartResults {
  assetDayDatas: Data[]
}

/**
 * Turns UNIX timestamp to day number.
 *
 * @example
 * timestampToDayNumber(1669941795)
 * // 19328
 * */
function timestampToDayNumber(timestamp: number): number {
  return parseInt((timestamp / ONE_DAY_UNIX).toFixed(0))
}

/**
 * Map to the first day that we have data on each chain. Check by query:
 *
 * ```gql
 * {
 *   assetDayDatas(first: 10, orderBy: dayID, orderDirection: asc) {
 *     blockNumber
 *     dailyCash
 *     dailyCashUSD
 *     dailyCollectedFee
 *     dailyCollectedFeeUSD
 *     dailyTradeVolumeUSD
 *     date
 *     dayID
 *     id
 *   }
 * }
 * ```
 *
 * If the value is 0, it means we have no data yet.
 * */
const networkIDToStartDayID: {
  [key in SupportedNetwork]: number
} = {
  [SupportedNetwork.BNB]: 19368,
  [SupportedNetwork.ARBITRUM]: 19444,
  [SupportedNetwork.ETHEREUM]: 19574,
  [SupportedNetwork.POLYGON]: 0,
  [SupportedNetwork.AVALANCHE]: 19650,
  [SupportedNetwork.BASE]: 0,
  [SupportedNetwork.OPTIMISM]: 19622,
  [SupportedNetwork.SCROLL]: 19647,
  [SupportedNetwork.KLAYTN]: 0,
}

type ChartDataCache = {
  lastCachedDayId: number
  lastCachedDay: Data[]
  data: {
    [dayId: number]: {
      date: number
      tvlUSD: number
      volumeUSD: number
    }
  }
}

const networkIDToChartData: {
  [key in SupportedNetwork]: ChartDataCache | null
} = {
  [SupportedNetwork.BNB]: {
    lastCachedDayId: 19753,
    lastCachedDay: BSCLastDayCacheData,
    data: BSCCacheData,
  },
  [SupportedNetwork.ARBITRUM]: {
    lastCachedDayId: 19753,
    lastCachedDay: ARBLastDayCacheData,
    data: ARBCacheData,
  },
  [SupportedNetwork.ETHEREUM]: {
    lastCachedDayId: 19753,
    lastCachedDay: ETHLastDayCacheData,
    data: ETHCacheData,
  },
  [SupportedNetwork.POLYGON]: null,
  [SupportedNetwork.AVALANCHE]: null,
  [SupportedNetwork.BASE]: null,
  [SupportedNetwork.OPTIMISM]: null,
  [SupportedNetwork.SCROLL]: null,
  [SupportedNetwork.KLAYTN]: null,
}

/**
 * We fetch all assets' daily snapshots, and aggregate those records to the end
 * result that is suitable for chart drawing. We use assets' daily snapshots
 * instead of protocol daily snapshots (that includes every assets' TVL and
 * volume) since the latter snapshots' numbers are negative and also needs to be
 * investigated.
 *
 * This should be called once as the page starts loading. The caller will write
 * this function's result to the global store for to let the data be used by
 * different charts.
 *
 * TODO: investigate why this and other data fetching functions are called many
 *       times on page loading.
 * */
async function fetchChartData(client: ApolloClient<NormalizedCacheObject>, networkId: SupportedNetwork) {
  let data: Data[] = []
  let error = false
  const limit = 1000 // larger than this would raise error from subgraph
  let allFound = false

  // Long-term fix for skip (offset) is capped at 6000:
  //
  // - We do fetch without skipping using a start day ID
  // - If the result count is < 1000, it means there is no further records
  // - If the result count is = 1000, it means we need to:
  //   - Drop the data with latest day ID
  //   - Concat the data
  //   - Go back to the first step, with the latest day ID
  const lastCachedDayId = networkIDToChartData[networkId]?.lastCachedDayId ?? 0
  let startDayId = Math.max(networkIDToStartDayID[networkId], lastCachedDayId)
  try {
    while (!allFound) {
      const {
        data: chartResData,
        error,
        loading,
      } = await client.query<ChartResults>({
        query: GLOBAL_CHART,
        variables: {
          limit,
          startDayId,
        },
        fetchPolicy: 'no-cache',
      })
      if (!loading) {
        let newData = chartResData.assetDayDatas
        if (error || chartResData.assetDayDatas.length < limit) {
          allFound = true
        } else {
          const latestDayNumberStr = newData[newData.length - 1].dayID
          newData = newData.filter((record) => record.dayID != latestDayNumberStr)
          startDayId = Number.parseInt(latestDayNumberStr)
        }
        data = data.concat(newData)
      }
    }
  } catch (err) {
    error = true
  }
  if (data) {
    // TODO: find out how to make importing JSON returns the actual data instead
    //       of returning a module that leads to this workaround.
    // @ts-ignore
    const lastCachedDay = networkIDToChartData[networkId]?.lastCachedDay.default ?? []
    data.push(...lastCachedDay)
    data = fillMissingData(data)
    // Use this debugger to get the data for `ChartDataCache.lastCachedDay`.
    // Relevant shell commands:
    //
    //   # enable the debugger and copy `data` to clipboard
    //   xclip -out -selection clipboard | jq > /tmp/day-data-bsc.json
    //   cat /tmp/day-data-bsc.json | jq '.[] | select (.dayID == "19753")' | jq -s > /tmp/last-day-data-bsc.json
    //   cp /tmp/last-day-data-bsc.json src/data/protocol/chart-cache-last-day-bsc.json
    //
    // debugger
    const dayTokenTvls: { [key: number]: { [key: string]: number } } = {}
    // We remove ankrBNB's pool volume from that particular date since the token
    // was exploited, and it resulted in an abnormal volume.
    const ankrExploitDayNumber = timestampToDayNumber(1669941795) // Friday, December 2, 2022 12:43:15 AM
    const formattedExisting = data.reduce((accum: { [date: number]: ChartDayData }, dayData) => {
      const dayID = parseInt(dayData.dayID)
      const tokenAddr = dayData.asset.underlyingToken.id
      const dayTvl =
        dayData.asset.poolAddress?.toLowerCase() == '0x0029b7e8e9ed8001c868aa09c74a1ac6269d4183' &&
        Number(dayData.dayID) >= ankrExploitDayNumber
          ? 0
          : +dayData.dailyLiability
      const dayVol =
        dayData.asset.poolAddress?.toLowerCase() == '0x0029b7e8e9ed8001c868aa09c74a1ac6269d4183' &&
        Number(dayData.dayID) >= ankrExploitDayNumber
          ? 0
          : parseFloat(dayData.dayVolume) / 2

      if (dayID in accum) {
        dayTokenTvls[dayID][tokenAddr] = dayTvl
        accum[dayID].tvlUSD += dayTvl
        accum[dayID].volumeUSD += dayVol
      } else {
        dayTokenTvls[dayID] = {}
        dayTokenTvls[dayID][tokenAddr] = dayTvl
        accum[dayID] = {
          date: parseInt((dayID * ONE_DAY_UNIX).toFixed(0)),
          tvlUSD: dayTvl,
          volumeUSD: dayVol,
        }
      }
      return accum
    }, {})

    const cachedData = networkIDToChartData[networkId]?.data ?? {}
    Object.assign(formattedExisting, cachedData)
    const todayDayNumber = timestampToDayNumber(new Date().getTime() / 1000)
    delete formattedExisting[todayDayNumber]
    // We need this since TypeScript is treating the cache data as a module,
    // which has a field named `default`. It messes with the data within
    // `formattedExisting`.
    // @ts-ignore
    delete formattedExisting.default
    // Use this debugger to get the data for `ChartDataCache.data` (aggregated
    // data). Relevant shell commands:
    //
    //   # enable the debugger and copy `formattedExisting` to clipboard
    //   xclip -out -selection clipboard | jq > /tmp/cache-data-bsc.json
    //   # IMPORTANT: delete key value pairs of "newer" days. For example, if we
    //   #            are caching from day ID `19753`, then we delete day ID
    //   #            day ID `19754` and so on.
    //   cp /tmp/cache-data-bsc.json src/data/protocol/chart-cache-bsc.json
    //
    // Remember to update `networkIDToChartData.lastDayID` accordingly.
    //
    // debugger

    return {
      data: Object.values(formattedExisting),
      error: false,
    }
  } else {
    return {
      data: undefined,
      error,
    }
  }
}

/**
 * Fetch historic chart data
 */
export function useFetchGlobalChartData(): {
  error: boolean
  data: ChartDayData[] | undefined
} {
  const [data, setData] = useState<{ [network: string]: ChartDayData[] | undefined }>()
  const [error, setError] = useState(false)
  const dataClient = useDataClient()[ClientType.MAIN]

  const [activeNetwork] = useActiveNetworkVersion()
  const indexedData = data?.[activeNetwork.id]
  useEffect(() => {
    async function fetch() {
      const { data, error } = await fetchChartData(dataClient, activeNetwork.id)
      if (data && !error) {
        setData({
          [activeNetwork.id]: data,
        })
      } else if (error) {
        setError(true)
      }
    }
    if (!error && !indexedData) {
      fetch()
    }
  }, [data, error, dataClient, activeNetwork.id, indexedData])

  return { error, data: indexedData }
}
