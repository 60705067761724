/* eslint-disable react/prop-types */
import React, { Dispatch, SetStateAction, ReactNode } from 'react'
import { BarChart, ResponsiveContainer, XAxis, Tooltip, Bar } from 'recharts'
import styled from 'styled-components'
import Card from 'components/Card'
import { RowBetween } from 'components/Row'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useTheme from 'hooks/useTheme'
import { VolumeWindow } from 'types'
import { Formatter } from 'recharts/types/component/DefaultTooltipContent'
dayjs.extend(utc)

const Wrapper = styled(Card)`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
  opacity: 0.95;
  flex-direction: column;
  > * {
    font-size: 1rem;
  }
`

export type LineChartProps = {
  data: any[]
  color?: string | undefined
  height?: number | undefined
  minHeight?: number
  setValue?: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setLabel?: Dispatch<SetStateAction<string | undefined>> // used for label of valye
  value?: number
  label?: string
  activeWindow?: VolumeWindow
  topLeft?: ReactNode | undefined
  topRight?: ReactNode | undefined
  bottom?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const CustomBar = ({
  x,
  y,
  width,
  height,
  fill,
}: {
  x: number
  y: number
  width: number
  height: number
  fill: string
}) => {
  return (
    <g>
      <rect x={x} y={y} fill={fill} width={width} height={height} rx="2" />
    </g>
  )
}

const Chart = ({ data, setValue, setLabel, value, label, activeWindow, ...rest }: LineChartProps) => {
  const theme = useTheme()
  const parsedValue = value
  type Payload = {
    payload?: {
      time: string
      value: number
    }
  }

  const now = dayjs()
  const formatterFn: Formatter<number, string> = (
    value: number,
    name: string,
    props: Payload
  ): ReactNode | [ReactNode, string] => {
    if (!props.payload) {
      return <></>
    }

    if (setValue && parsedValue !== props.payload.value) {
      setValue(props.payload.value)
    }
    const formattedTime = dayjs(props.payload.time).format('MMM D')
    const formattedTimeDaily = dayjs(props.payload.time).format('MMM D YYYY')
    const formattedTimePlusWeek = dayjs(props.payload.time).add(1, 'week')
    const formattedTimePlusMonth = dayjs(props.payload.time).add(1, 'month')

    if (setLabel && label !== formattedTime) {
      if (activeWindow === VolumeWindow.weekly) {
        const isCurrent = formattedTimePlusWeek.isAfter(now)
        setLabel(formattedTime + '-' + (isCurrent ? 'current' : formattedTimePlusWeek.format('MMM D, YYYY')))
      } else if (activeWindow === VolumeWindow.monthly) {
        const isCurrent = formattedTimePlusMonth.isAfter(now)
        setLabel(formattedTime + '-' + (isCurrent ? 'current' : formattedTimePlusMonth.format('MMM D, YYYY')))
      } else {
        setLabel(formattedTimeDaily)
      }
    }
    return <></>
  }

  return (
    <Wrapper {...rest}>
      <div style={{ position: 'relative', width: '100%', height: '150px' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <ResponsiveContainer width="100%" height={180}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                bottom: 5,
              }}
              onMouseLeave={() => {
                setLabel && setLabel(undefined)
                setValue && setValue(undefined)
              }}
            >
              <XAxis
                dataKey="time"
                axisLine={false}
                tickLine={false}
                tickFormatter={(time) => dayjs(time).format(activeWindow === VolumeWindow.monthly ? 'MMM' : 'DD')}
                minTickGap={10}
              />
              <Tooltip cursor={{ fill: theme.bg2 }} contentStyle={{ display: 'none' }} formatter={formatterFn} />
              <defs>
                <linearGradient
                  id="barColor"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="100%"
                  spreadMethod="reflect"
                  gradientTransform="rotate(180.72)"
                >
                  <stop offset="62.74%" stopColor="#ef893c" />
                  <stop offset="121.09%" stopColor="#ef893c" />
                </linearGradient>
              </defs>
              <Bar
                dataKey="value"
                fill={'url(#barColor)'}
                shape={(props: { height: number; width: number; x: number; y: number }) => {
                  return (
                    <CustomBar
                      height={props.height}
                      width={props.width}
                      x={props.x}
                      y={props.y}
                      fill={'url(#barColor)'}
                    />
                  )
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Wrapper>
  )
}

export default Chart
