import { ProtocolData, StakedWomData } from '../../state/protocol/reducer'
import gql from 'graphql-tag'
import { useQuery, ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { useDeltaTimestamps } from 'utils/queries'
import { useBlocksFromTimestamps } from 'hooks/useBlocksFromTimestamps'
import { useMemo } from 'react'
import { useWomCirculatingSupplyClient, useBribeClient } from '../../state/application/hooks'
import { BNBNetworkInfo, SupportedNetwork } from 'constants/networks'
import { useActiveNetworkVersion } from '../../state/application/hooks'
import {
  arbitrumBlockClient,
  arbitrumClient,
  avalancheBlockClient,
  avalancheClient,
  baseBlockClient,
  baseClient,
  bnbBlockClient,
  bnbClient,
  optimismBlockClient,
  optimismClient,
  scrollBlockClient,
  scrollClient,
} from 'apollo/client'

export const GLOBAL_TOKENS = (block?: string) => {
  const queryString = `
    query assets {
      assets(subgraphError: allow, ${
        block !== undefined ? `block: { number: ${block}}` : ``
      }, where: {id_not:"0x0000000000000000000000000000000000000000"}) {
        id
        underlyingToken{
          symbol
          decimals
        }
        totalTradeVolume: totalTradeVolumeUSD
      }
    }
  `
  return gql(queryString)
}

export const GLOBAL_TOKENS_BNB = (block?: string) => {
  const queryString = `
    query tokens {
      tokens(subgraphError: allow, ${block !== undefined ? `block: { number: ${block}}` : ``}) {
        id
        symbol
        totalTradeVolume: totalTradeVolumeUSD
        decimals
      }
    }
  `
  return gql(queryString)
}
const GLOBAL_ASSETS = gql`
  query assets {
    assets(subgraphError: allow, where: { id_not: "0x0000000000000000000000000000000000000000" }) {
      liability: liabilityUSD
      totalCollectedFeeUSD
      underlyingToken {
        id
      }
    }
  }
`

export const TOTAL_BRIBE = gql`
  {
    overviews {
      totalBribeRevenue
    }
  }
`

export const TOTAL_BRIBE_ARB = gql`
  {
    overviews: protocols {
      totalBribeRevenue: totalBribeRevenueUSD
    }
  }
`
export interface BribeDataResponse {
  overviews: {
    totalBribeRevenue: number
  }[]
}

export const GLOBAL_STAKED_WOM = (
  block: number | undefined,
  block24: number | undefined,
  blockWeek: number | undefined
) => {
  const queryString = `
    query veWoms {
      stakedWom: veWoms(subgraphError: allow, ${block !== undefined ? `block: { number: ${block}}` : ``}) {
        currentTotalWomLocked
      }
      stakedWom24: veWoms(subgraphError: allow, ${block24 !== undefined ? `block: { number: ${block24}}` : ``}) {
        currentTotalWomLocked
      }
      stakedWomWeek: veWoms(subgraphError: allow, ${blockWeek !== undefined ? `block: { number: ${blockWeek}}` : ``}) {
        currentTotalWomLocked
      }
      veWoms(subgraphError: allow) {
        avgLockTime
        currentTotalSupply
      }
    }
  `
  return gql(queryString)
}

const GLOBAL_WOM_SUPPLY = gql`
  query womSupplyData {
    wombatTokens(subgraphError: allow) {
      circulatingSupply
      totalLockedSupply
      totalVestedAmount
    }
  }
`

interface TokenData {
  id: string
  underlyingToken: {
    decimals: string
    symbol: string
  }
  totalTradeVolume: string
}

export interface StakedWomPeriodData {
  currentTotalWomLocked: number
}

export interface VeWomData {
  avgLockTime: number
  currentTotalSupply: number
}

export interface WomSupplyData {
  circulatingSupply: number
  totalLockedSupply: number
  totalVestedAmount: number
}

export interface GlobalTokensResponse {
  assets: TokenData[]
}

interface AssetData {
  liability: string
  totalCollectedFeeUSD: string
  underlyingToken: {
    id: string
  }
}

interface GlobalAssetsResponse {
  assets: AssetData[]
}

interface GlobalVeWomDayDatasResponse {
  stakedWom: StakedWomPeriodData[]
  stakedWom24: StakedWomPeriodData[]
  stakedWomWeek: StakedWomPeriodData[]
  veWoms: VeWomData[]
}
interface GlobalWomSupplyDataResponse {
  wombatTokens: WomSupplyData[]
}

function parseTradeVolume(tokenDatas: TokenData[] | undefined): number | undefined {
  return tokenDatas?.reduce((accum: number, tokenData) => {
    const tokenTotalTradeVolume = parseFloat(tokenData.totalTradeVolume) / 2
    accum += tokenTotalTradeVolume
    return accum
  }, 0)
}

export function parseTokensDecimals(tokenDatas: TokenData[] | undefined): { [key: string]: number } | undefined {
  return tokenDatas?.reduce((accum: { [id: string]: number }, tokenData) => {
    const tokenId = tokenData.id
    const tokenDecimals = parseInt(tokenData.underlyingToken.decimals)
    accum[tokenId] = tokenDecimals
    return accum
  }, {})
}

function parseStakedWomDataResponse(response: GlobalVeWomDayDatasResponse | undefined): StakedWomData {
  if (!response) {
    return { stakedToday: 0, stakedYesterday: 0, stakedLastWeek: 0, avgLockTimeInSec: 0, currentTotalSupply: 0 }
  }
  const parsed = response?.stakedWom
  const parsed24 = response?.stakedWom24
  const parsedWeek = response?.stakedWomWeek
  const parsedVeWoms = response?.veWoms
  let stakedToday = 0,
    stakedYesterday = 0,
    stakedLastWeek = 0
  if (parsed && parsed24 && parsedWeek && parsed[0] && parsed24[0] && parsedWeek[0]) {
    stakedToday = parsed[0].currentTotalWomLocked
    stakedYesterday = parsed24[0].currentTotalWomLocked
    stakedLastWeek = parsedWeek[0].currentTotalWomLocked
  }
  const avgLockTimeInSec = parsedVeWoms[0] ? parsedVeWoms[0].avgLockTime : 0
  const currentTotalSupply = parsedVeWoms[0] ? parsedVeWoms[0].currentTotalSupply : 0
  return { stakedToday, stakedYesterday, stakedLastWeek, avgLockTimeInSec, currentTotalSupply }
}

function parseWomSupplyData(womSupplyDatas: WomSupplyData[] | undefined): WomSupplyData {
  let circulatingSupply = 0,
    totalLockedSupply = 0,
    totalVestedAmount = 0
  if (Array.isArray(womSupplyDatas) && womSupplyDatas.length >= 1) {
    circulatingSupply = womSupplyDatas[womSupplyDatas.length - 1].circulatingSupply
    totalLockedSupply = womSupplyDatas[womSupplyDatas.length - 1].totalLockedSupply
    totalVestedAmount = womSupplyDatas[womSupplyDatas.length - 1].totalVestedAmount
  }
  return { circulatingSupply, totalLockedSupply, totalVestedAmount }
}

export function useFetchProtocolData(
  dataClient: ApolloClient<NormalizedCacheObject>,
  blockClient: ApolloClient<NormalizedCacheObject>
): {
  loading: boolean
  error: boolean
  data: ProtocolData | undefined
} {
  const [activeNetwork] = useActiveNetworkVersion()
  const bribeClient = useBribeClient()
  const womCirculatingSupplyClient = useWomCirculatingSupplyClient()
  const [t15min, t24, , tWeek] = useDeltaTimestamps()
  const { blocks } = useBlocksFromTimestamps([t15min, t24, tWeek], blockClient)
  const [block15min, block24] = blocks ?? []
  const { loading, error, data } = useQuery<GlobalTokensResponse>(GLOBAL_TOKENS(block15min?.number ?? '0'), {
    client: dataClient,
  })

  const {
    loading: loading24,
    error: error24,
    data: data24,
  } = useQuery<GlobalTokensResponse>(GLOBAL_TOKENS(block24?.number ?? '0'), { client: dataClient })

  const {
    loading: loadingAssets,
    error: errorAssets,
    data: dataAssets,
  } = useQuery<GlobalAssetsResponse>(GLOBAL_ASSETS, { client: dataClient })

  const { blocks: blocksBsc, error: blocksErrorBsc } = useBlocksFromTimestamps([t15min, t24, tWeek], bnbBlockClient)
  const [block15minBsc, block24Bsc, blockWeekBsc] = blocksBsc ?? []
  const veWomDataBsc = useQuery<GlobalVeWomDayDatasResponse>(
    GLOBAL_STAKED_WOM(block15minBsc?.number, block24Bsc?.number, blockWeekBsc?.number),
    {
      client: bnbClient,
    }
  )

  const { blocks: blocksArb, error: blocksErrorArb } = useBlocksFromTimestamps(
    [t15min, t24, tWeek],
    arbitrumBlockClient
  )
  const [block15minArb, block24Arb, blockWeekArb] = blocksArb ?? []
  const veWomDataArb = useQuery<GlobalVeWomDayDatasResponse>(
    GLOBAL_STAKED_WOM(block15minArb?.number, block24Arb?.number, blockWeekArb?.number),
    {
      client: arbitrumClient,
    }
  )

  const { blocks: blocksAvax, error: blocksErrorAvax } = useBlocksFromTimestamps(
    [t15min, t24, tWeek],
    avalancheBlockClient
  )
  const [block15minAvax, block24Avax, blockWeekAvax] = blocksAvax ?? []
  const veWomDataAvax = useQuery<GlobalVeWomDayDatasResponse>(
    GLOBAL_STAKED_WOM(block15minAvax?.number, block24Avax?.number, blockWeekAvax?.number),
    {
      client: avalancheClient,
    }
  )

  const { blocks: blocksBase, error: blocksErrorBase } = useBlocksFromTimestamps([t15min, t24, tWeek], baseBlockClient)
  const [block15minBase, block24Base, blockWeekBase] = blocksBase ?? []
  const veWomDataBase = useQuery<GlobalVeWomDayDatasResponse>(
    GLOBAL_STAKED_WOM(block15minBase?.number, block24Base?.number, blockWeekBase?.number),
    {
      client: baseClient,
    }
  )

  const { blocks: blocksScroll, error: blocksErrorScroll } = useBlocksFromTimestamps(
    [t15min, t24, tWeek],
    scrollBlockClient
  )
  const [block15minScroll, block24Scroll, blockWeekScroll] = blocksScroll ?? []
  const veWomDataScroll = useQuery<GlobalVeWomDayDatasResponse>(
    GLOBAL_STAKED_WOM(block15minScroll?.number, block24Scroll?.number, blockWeekScroll?.number),
    {
      client: scrollClient,
    }
  )

  const { blocks: blocksOptimism, error: blocksErrorOptimism } = useBlocksFromTimestamps(
    [t15min, t24, tWeek],
    optimismBlockClient
  )
  const [block15minOptimisim, block24Optimism, blockWeekOptimism] = blocksOptimism ?? []
  const veWomDataOptimism = useQuery<GlobalVeWomDayDatasResponse>(
    GLOBAL_STAKED_WOM(block15minOptimisim?.number, block24Optimism?.number, blockWeekOptimism?.number),
    {
      client: optimismClient,
    }
  )

  const {
    loading: loadingBribe,
    error: errorBribe,
    data: dataBribe,
  } = useQuery<BribeDataResponse>(activeNetwork == BNBNetworkInfo ? TOTAL_BRIBE : TOTAL_BRIBE_ARB, {
    client: activeNetwork == BNBNetworkInfo ? bribeClient : dataClient,
  })

  const getVeWomData = (chainId: number) => {
    switch (chainId) {
      case SupportedNetwork.ARBITRUM:
        return veWomDataArb
      case SupportedNetwork.AVALANCHE:
        return veWomDataAvax
      case SupportedNetwork.BASE:
        return veWomDataBase
      case SupportedNetwork.OPTIMISM:
        return veWomDataOptimism
      case SupportedNetwork.SCROLL:
        return veWomDataScroll
      case SupportedNetwork.BNB:
        return veWomDataBsc
      default:
        return undefined
    }
  }

  let stakedWomData: { [id in SupportedNetwork]?: StakedWomData } = {}

  for (const network in SupportedNetwork) {
    const id = SupportedNetwork[network]
    if (typeof id === 'number') {
      const data = parseStakedWomDataResponse(getVeWomData(id)?.data)
      stakedWomData = {
        ...stakedWomData,
        [id as SupportedNetwork]: data,
      }
    }
  }

  const {
    loading: loadingWomSupply,
    error: errorWomSupply,
    data: dataWomSupply,
  } = useQuery<GlobalWomSupplyDataResponse>(GLOBAL_WOM_SUPPLY, { client: womCirculatingSupplyClient })
  const parsedTokens = data?.assets
  const parsed24Tokens = data24?.assets
  const parsedAssets = dataAssets?.assets
  const parsedWomSupplyData = dataWomSupply?.wombatTokens
  const totalBribeRevenue = dataBribe ? dataBribe.overviews[0].totalBribeRevenue : 0
  const anyError = Boolean(
    error ||
      error24 ||
      errorAssets ||
      blocksErrorArb ||
      blocksErrorAvax ||
      blocksErrorBase ||
      blocksErrorBsc ||
      blocksErrorOptimism ||
      blocksErrorScroll ||
      veWomDataArb?.error ||
      veWomDataAvax?.error ||
      veWomDataBase?.error ||
      veWomDataBsc?.error ||
      veWomDataOptimism?.error ||
      veWomDataScroll?.error ||
      errorWomSupply ||
      errorBribe
  )
  const anyLoading = Boolean(
    loading ||
      loading24 ||
      loadingAssets ||
      veWomDataArb?.loading ||
      veWomDataAvax?.loading ||
      veWomDataBase?.loading ||
      veWomDataBsc?.loading ||
      veWomDataOptimism?.loading ||
      veWomDataScroll?.loading ||
      loadingWomSupply ||
      loadingBribe
  )
  const allParsed = Boolean(
    parsedTokens && parsed24Tokens && parsedAssets && stakedWomData && parsedWomSupplyData && totalBribeRevenue
  )

  const formattedData: ProtocolData | undefined = useMemo(() => {
    if (anyError || anyLoading || !allParsed) {
      return undefined
    }

    const totalTradeVolume = parseTradeVolume(parsedTokens) ?? 0
    const totalTradeVolume24 = parseTradeVolume(parsed24Tokens) ?? 0
    const allTokenDecimals = parseTokensDecimals(parsedTokens)

    const tvlUSD =
      parsedAssets?.reduce((accum: number, assetData) => {
        const assetTvlString = assetData.liability
        const decimals = allTokenDecimals?.[assetData.underlyingToken.id]
        if (decimals) {
          accum += +assetTvlString
        } else {
          accum += parseFloat(assetTvlString)
        }
        return accum
      }, 0) || 0

    const totalFeeCollected =
      parsedAssets?.reduce((accum: number, assetData) => {
        const feeString = assetData.totalCollectedFeeUSD
        accum += parseFloat(feeString)
        return accum
      }, 0) || 0

    const volumeUSD = totalTradeVolume - totalTradeVolume24
    const womSupplyData = parseWomSupplyData(parsedWomSupplyData)
    const results =
      tvlUSD || volumeUSD || stakedWomData || womSupplyData || totalFeeCollected || totalBribeRevenue
        ? {
            tvlUSD,
            volumeUSD,
            totalFeeCollected,
            stakedWomData,
            womSupplyData,
            totalBribeRevenue,
          }
        : undefined

    return results
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anyError, anyLoading, parsedTokens, parsed24Tokens, parsedAssets, parsedWomSupplyData])

  return {
    loading: anyLoading,
    error: anyError,
    data: formattedData,
  }
}
